document.addEventListener("DOMContentLoaded", function(event) {
  // You can use either a string for the player ID (i.e., `player`),
  // or `document.querySelector()` for any selector
  const playerElement = document.querySelector('.js-video');
  const player = new MediaElementPlayer(playerElement, {
    // pluginPath: "/path/to/shims/",
    // When using `MediaElementPlayer`, an `instance` argument
    // is available in the `success` callback
    stretching: 'responsive',
    hideVideoControlsOnLoad: true,
    controlsTimeoutMouseLeave: 200,
    success: function(mediaElement, originalNode, instance) {
      console.log('start');
    }
  });
});

document.addEventListener("DOMContentLoaded", function(event) {
  const $toggleButton = document.querySelector('.js-toggle-sub-sub-menu');
  const $subsubmenu = document.querySelector('.js-sub-sub-menu');

  if ($toggleButton) {
    $toggleButton.addEventListener('click', function() {
      $subsubmenu.classList.toggle('is-closed');
    });
  }
});
